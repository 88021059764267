.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

td {
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 10px;
}

td:hover {
  background-color: #13151a;
}